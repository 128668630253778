import React, { Component } from "react";
import TextExplanation from "./TextExplanation";
import CodeDisplay from "./CodeDisplay";
import { Link } from "react-router-dom";

export class TopicHtml extends Component {
  render() {
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Link to="/"> Back </Link>
        <h1>HTML review</h1>
        <TextExplanation
          text={
            "HTML is one of the markup languages that was defined using the SGML framework. It simplifies many of SGML's capabilities to make it easier to use for creating web pages. HTML uses a fixed set of tags defined by SGML rules to describe the structure and presentation of web content (such as text, links, images, and multimedia)."
          }
        />
        <TextExplanation
          textspeech={
            "The main structure of an HTML document consist of: The declaration Doctype html, html, head, and body tags."
          }
          text={
            "The main structure of an HTML document consist of: The declaration <!DOCTYPE html>, <html>, <head>, and <body> tags."
          }
        />
        <TextExplanation text={"Example of a basic HTML document:"} />
        <CodeDisplay
          code='<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title>HTML Structure</title></head><body><h1>Hello World!</h1></body></html>'
          language="html"
        />

        <TextExplanation
          textspeech={
            "so that means some package in the tree got updated and now the closest version in the three is v8"
          }
          text={
            "so that means some package in the tree got updated and now the closest version in the three is v8"
          }
        />
        <CodeDisplay
          code='<div id="div1" class="row">hello world</div><ul><li>Item1</li><li>Item2</li></ul>'
          language="html"
        />
      </div>
    );
  }
}

export default TopicHtml;
