import React, { useEffect, useState } from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
// import javascript from "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { duotoneLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  html as beautifyHtml,
  css as beautifyCss,
  js as beautifyJs,
} from "js-beautify";

const languageMap = {
  html: beautifyHtml,
  css: beautifyCss,
  javascript: beautifyJs,
};

const CodeDisplay = ({ code, language }) => {
  const [formattedCode, setFormattedCode] = useState("");

  useEffect(() => {
    const beautify = languageMap[language] || ((code) => code);
    try {
      setFormattedCode(beautify(code));
    } catch (error) {
      console.error("Error formatting the code:", error);
      setFormattedCode(code); // Use original code on error
    }
  }, [code, language]);

  return (
    <SyntaxHighlighter language={language} style={atomDark}>
      {formattedCode}
    </SyntaxHighlighter>
  );
};

export default CodeDisplay;
