import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePageEF from "./components/HomePageEF";
import TopicHtml from "./components/TopicHtml";
// import TopicPage from "./TopicPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/EF" element={<HomePageEF />} />
        <Route path="/html" element={<TopicHtml />} />
        {/* <Route path="/topic/:topicId" element={<TopicPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
