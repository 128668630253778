import React from "react";
import { Link } from "react-router-dom";

const HomePage = () => {
  const topics = [
    { id: 1, name: "Introduction to React", path: "/topic/react" },
    { id: 2, name: "JavaScript Basics", path: "/topic/js-basics" },
  ];

  return (
    <div>
      <h1>Learning Platform</h1>
      <ul>
        <li>
          <Link to="/html">HTML</Link>
        </li>
        {/* {topics.map((topic) => (
          <li key={topic.id}>
            <Link to={topic.path}>{topic.name}</Link>
          </li>
        ))} */}
      </ul>
    </div>
  );
};

export default HomePage;
