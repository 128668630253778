import React, { useState, useRef } from "react";

const TextExplanation = ({ textspeech, text }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const utteranceRef = useRef(null);

  // Check if speechSynthesis is supported
  const isSpeechSynthesisSupported =
    typeof window.speechSynthesis !== "undefined";

  const handlePlay = () => {
    if (!isSpeechSynthesisSupported) {
      console.error("Speech Synthesis API is not supported in this browser.");
      return;
    }

    if (!utteranceRef.current) {
      const speechText = textspeech || text; // Use textspeech if available, otherwise use text
      const speech = new SpeechSynthesisUtterance(speechText);
      utteranceRef.current = speech;
      speech.onend = () => {
        setIsSpeaking(false);
        setIsPaused(false);
        utteranceRef.current = null;
      };
      speech.onerror = (event) => {
        console.error("SpeechSynthesisUtterance error:", event);
        setIsSpeaking(false);
        setIsPaused(false);
        utteranceRef.current = null;
      };
    }
    setIsSpeaking(true);
    setIsPaused(false);
    window.speechSynthesis.speak(utteranceRef.current);
  };

  const handlePause = () => {
    if (isSpeechSynthesisSupported && window.speechSynthesis.speaking) {
      window.speechSynthesis.pause();
      setIsPaused(true);
    }
  };

  const handleResume = () => {
    if (isSpeechSynthesisSupported && window.speechSynthesis.paused) {
      window.speechSynthesis.resume();
      setIsPaused(false);
    }
  };

  const handleStop = () => {
    if (isSpeechSynthesisSupported) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      setIsPaused(false);
      utteranceRef.current = null;
    }
  };

  return (
    <div>
      {!isSpeaking && <button onClick={handlePlay}>Play</button>}
      {isSpeaking && !isPaused && <button onClick={handlePause}>Pause</button>}
      {isSpeaking && isPaused && <button onClick={handleResume}>Resume</button>}
      {isSpeaking && <button onClick={handleStop}>Stop</button>}
      <p>{text}</p>
    </div>
  );
};

export default TextExplanation;
